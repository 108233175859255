<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row wrap v-if="userDetails.isActive">
      <v-col cols="3" class="mt-3">
            <span class="orderStatText">
              <v-dialog v-model="dialog" persistent max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dense block dark color="#72bd61" v-bind="attrs" v-on="on">
                    <v-icon big >mdi-pill</v-icon> Add Product
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="#000000" @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-title>
                    <v-row>
                      <v-col
                        cols="12"
                        align="center"
                        justify="center"
                        v-if="!updateProduct"
                      >
                        <p><v-icon size="42">mdi-cart-plus</v-icon></p>
                        <p class="text-h5">
                          Add a Product
                        </p>
                      </v-col>

                      <v-col
                        cols="12"
                        align="center"
                        justify="center"
                        v-if="updateProduct"
                      >
                        <p>
                          <v-icon size="42" color="#72BD61"
                            >mdi-briefcase-edit</v-icon
                          >
                        </p>
                        <p class="text-h5">
                          Edit a Product
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="productData.name"
                            dense
                            outlined
                            label="Product Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="productData.productCategoryId"
                            :items="categories"
                            item-text="name"
                            item-value="productCategoryId"
                            label="Select Category"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="productData.price"
                            label="Product Price"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="productData.description"
                            label="Product Description"
                            outlined
                            dense
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="productData.discount"
                            label="Product Discount"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-switch
                            small
                            v-model="productData.displayDiscount"
                            outlined
                            color="#72bd61"
                            label="Display Discount"
                          ></v-switch>
                        </v-col>

                        <v-col cols="12">
                          <v-switch
                            small
                            v-model="productData.displayReviews"
                            outlined
                            color="#BD2121"
                            label="Show Product Reviews"
                          ></v-switch>
                        </v-col>

                        <v-col cols="12">
                          <v-switch
                            small
                            v-model="productData.isPrescription"
                            outlined
                            color="#BD2121"
                            label="Prescription Drug?"
                          ></v-switch>
                        </v-col>

                        <v-col cols="12">
                          <v-switch
                            small
                            v-model="productData.isActive"
                            outlined
                            color="#BD2121"
                            label="Product Active"
                          ></v-switch>
                        </v-col>

                        <v-col cols="12">
                          <v-select
                            v-model="productData.regions"
                            :items="productRegion"
                            item-text="name"
                            item-value="regionId"
                            label="Choose Region"
                            multiple
                            outlined
                            dense
                          ></v-select>
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            v-model="productData.quantity"
                            label="Quantity Available"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-row>
                            <v-col cols="12">
                              Upload Product Image
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" align="center" justify="center">
                              <v-icon size="100" @click="onPickAvatar">
                                mdi-cloud-download-outline
                              </v-icon>
                              <input
                                type="file"
                                style="display:none;"
                                ref="fileInput"
                                accept="image/*"
                                @change="avatarPicked"
                              />
                              <v-progress-linear
                                class="mt-3"
                                color="#7CBF46"
                                v-show="imageLoader"
                                :indeterminate="true"
                              ></v-progress-linear>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              v-show="preview"
                              align="center"
                              justify="center"
                            >
                              <v-img
                                class="centerImg"
                                contain
                                :src="productImage"
                                width="250"
                                align="center"
                              >
                              </v-img>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider></v-divider>
                      </v-row>

                      <v-row color="#BD2121">
                        <v-col cols="12">
                          <v-list>
                            <v-list-item
                              v-for="(error, index) in errorMessage"
                              :key="index"
                            >
                              <v-list-item-content>
                                <v-list-item-title class="text-danger"
                                  ><v-icon color="#F81515">mdi-cancel</v-icon>
                                  {{ error }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-row>
                      <v-col cols="12" align="center" justify="center">
                        <v-layout>
                          <v-progress-linear
                            class="mt-3"
                            color="#7CBF46"
                            v-show="loader"
                            :indeterminate="true"
                          ></v-progress-linear>
                        </v-layout>
                        <v-layout>
                          <v-btn
                            class="buttonStyle"
                            block
                            v-if="!updateProduct"
                            @click="saveProduct"
                          >
                            Add Product
                          </v-btn>

                          <v-btn
                            class="buttonStyle"
                            v-if="updateProduct"
                            @click="editProduct(productData)"
                          >
                            Update
                          </v-btn>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" persistent max-width="400px">
                <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="#000000" @click="dialogDelete = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-layout wrap>
                      <v-col cols="12" align="center">
                        <v-img
                          alt="System Logo"
                          class="shrink mr-2"
                          contain
                          :src="'img/warningSign.png'"
                          transition="scale-transition"
                          width="100"
                        />
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col class="text-h6" cols="12" align="center">
                        Do you wish to continue
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col cols="12" align="center">
                        click yes if you wish to continue and No if you wish to
                        cancel this. Note products delected cannot be available
                        anymore in your store.
                      </v-col>
                    </v-layout>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="buttonStyleWarning" dark @click="closeDelete"
                      >No</v-btn
                    >
                    <v-btn class="buttonStyle" dark @click="deleteItemConfirm"
                      >Yes</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="dialogEditSuccess"
                persistent
                max-width="400px"
              >
                <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      color="#000000"
                      @click="dialogEditSuccess = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-layout wrap>
                      <v-col cols="12" align="center">
                        <v-icon color="#72BD61" size="100"
                          >mdi-check-circle</v-icon
                        >
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col
                        v-if="updateProduct"
                        class="text-h6"
                        cols="12"
                        align="center"
                      >
                        Product Edited Successfully
                      </v-col>
                      <v-col
                        v-if="!updateProduct"
                        class="text-h6"
                        cols="12"
                        align="center"
                      >
                        Product added Successfully
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col cols="12" align="center" v-if="updateProduct">
                        Hurray, your product was successfully updated
                      </v-col>

                      <v-col cols="12" align="center" v-if="!updateProduct">
                        Your product has been uploaded to your store
                        successfully. You can add more product to your store or
                        cancel
                      </v-col>
                    </v-layout>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="buttonStyle"
                      dark
                      @click="refreshPage"
                      v-if="updateProduct"
                      >Done</v-btn
                    >
                    <v-layout v-if="!updateProduct" wrap>
                      <v-col cols="11" offset="1">
                        <v-btn class="buttonStyle" dark @click="refreshPage"
                          >Continue
                          <v-icon color="#ffffff;">mdi-plus</v-icon></v-btn
                        >
                      </v-col>
                    </v-layout>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- Add Image -->
              <v-dialog v-model="dialogAddImages" width="700">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Add more product images
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogAddImages = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                    <v-layout wrap>
                      <v-col cols="12" md="3" class="mt-2">
                        <v-icon size="100" @click="onPickimages">
                          mdi-cloud-download-outline
                        </v-icon>
                        <input
                          type="file"
                          style="display:none;"
                          ref="fileInput2"
                          multiple
                          accept="image/*"
                          @change="imagesPicked"
                        />
                        <v-progress-linear
                          class="mt-3"
                          color="#7CBF46"
                          v-show="imageLoader2"
                          :indeterminate="true"
                        ></v-progress-linear>
                      </v-col>
                      <v-col cols="12" md="8" v-show="preview2">
                        <v-layout wrap>
                          <v-col
                            cols="4"
                            v-for="(image, key) in images"
                            :key="key"
                            style="border:1px solid black;border-radius:5px;"
                          >
                            <!-- <span>
                                          <v-btn
                                              icon
                                              @click="removeImage(image)"
                                            >
                                              <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </span> -->
                            <v-img
                              class="centerImg"
                              contain
                              :ref="'image'"
                              height="150"
                              align="center"
                            >
                            </v-img>
                          </v-col>
                        </v-layout>
                      </v-col>
                    </v-layout>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      color="primary"
                      text
                      @click="saveImages"
                      v-if="selectedFiles.length > 0"
                    >
                      Upload
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </span>
      </v-col>
      <v-col cols="12" md="3">
        <v-row wrap>
          <v-col cols="2" class="mt-2">
            <span class="orderStatText">Filter: </span>
          </v-col>
          <v-col cols="10">
            <v-select
              :items="categories"
              item-text="name"
              item-value="productCategoryId"
              label="Categories"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" align="right" justify="right">
        <v-row wrap>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              outlined
              label="Search for a product..."
              dense
              append-icon="mdi-magnify"
              single-line
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <v-row wrap v-if="userDetails.isActive">
      <v-col cols="12">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="allProducts"
          item-key="name"
          class="elevation-1"
          :search="search"
          show-select
        >
          <template
            v-slot:item.image="{ item }"
            style="width:100px !important;"
          >
            <td>
              <p>
                <v-img
                  class="ma-2"
                  width="70"
                  height="70"
                  :src="item.productImagePath"
                  cover
                />
              </p>
              <p>
                <v-btn small rounded v-on:click="addImages(item)"
                  >Add Images</v-btn
                >
              </p>
            </td>
          </template>

          <template v-slot:item.discount="{ item }">
            <v-layout wrap>
              <span style="color:#BD2121">{{ item.discount }}</span>
            </v-layout>
          </template>

          <template v-slot:item.status="{ item }">
            <v-layout wrap v-if="item.isActive == false">
              <span style="color:#72bd61">{{ item.isActive }}</span>
            </v-layout>
            <v-layout v-if="item.isActive !== true">
              <span style="color:#BD2121">{{ item.isActive }}</span>
            </v-layout>
          </template>

          <template v-slot:item.displayDiscount="{ item }">
            <v-switch
              wrap
              small
              v-model="item.displayDiscount"
              inset
              color="#72bd61"
            ></v-switch>
          </template>

          <template v-slot:item.prescription="{ item }">
                <v-layout wrap v-if="item.needsPrescription == false">
                  <span style="color:#72bd61">No</span>
                </v-layout>
                <v-layout v-if="item.needsPrescription !== true">
                  <span style="color:#BD2121">Yes</span>
                </v-layout>
              </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              v-on:click="editItem(item)"
              color="#BD2121"
            >
              mdi-pencil
            </v-icon>
            <v-icon small v-on:click="deleteItem(item)" color="#BD2121">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row wrap v-else class="mb-10">
          <v-col cols="12" align="Center">
              <v-layout wrap>
                <v-col cols="12" align="Center">
                  <v-icon size="200" color="red">mdi-alert-circle</v-icon>
                </v-col>
              </v-layout>
              <h3>Account awaiting approval</h3>
              <v-layout wrap>
                <v-col cols="12" md="10" offset-md="1" align="Center">
                   <p>Hello <b>{{userDetails.name}}</b>! We are glad to have you sign up on qikPharma as a <b>Distributor</b>. Your account is currently under review and will be approved sooner than you can imagine.</p>
                </v-col>
              </v-layout>
             
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AllProducts",

  data: () => ({
    dialog: false,
    dialogEdit: false,
    dialogDelete: false,
    dialogEditSuccess: false,
    dialogAddImages: false,
    imageLoader: false,
    selectedFiles: [],
    loader: false,
    userDetails:{
      isActive:true,
    },
    productData: {
      name: "",
      productCategoryId: "",
      price: "",
      description: "",
      isActive: null,
      discount: 0,
      displayDiscount: false,
      displayReviews: false,
      regions: [],
      quantity: "",
      isPrescription: false,
    },
    updateProduct: false,
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/",
      },
      {
        text: "My Account",
        disabled: false,
        href: "/#/overview/distributor_dashboard/en/home",
      },
      {
        text: "All Products",
        disabled: true,
        href: "/#/en/distributor/all/products",
      },
    ],
    imageLoader2: false,
    userId: "",
    categories: [],
    productImage: "",
    selectedFile: "",
    preview: "",
    productStatus: [true, false],
    productRegion: [],
    search: "",
    selected: [],
    allProducts: [],
    errorMessage: [],
    images: [],
    preview2: "",
  }),
  computed: {
    headers() {
      return [
        {
          text: "Image",
          value: "image",
        },
        { text: "Name", value: "name" },
        { text: "Status", value: "isActive" },
        { text: "Price", value: "price" },
        { text: "Quantity", value: "quantity" },
        { text: "Prescription", value: "needsPrescription" },
        { text: "Discount", value: "discount" },
        { text: "Discount Status", value: "displayDiscount" },
        { text: "Action", value: "actions" },
      ];
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.userId = localStorage.getItem("userId");
    this.getUserDetails();
    this.getRegions();
    this.getProductCat();
    this.getUserProducts();
  },
  methods: {
    getUserDetails() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem("userId"))
        .then((response) => {
          this.userDetails = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getUserProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product/user/` + this.userId)
        .then((response) => {
          if (response.data.success == true) {
            this.allProducts = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    onPickAvatar() {
      this.imageLoader = true;
      this.$refs.fileInput.click();
    },
    avatarPicked(e) {
      this.selectedFile = e.target.files[0];

      var fileReader = new FileReader();

      fileReader.readAsDataURL(e.target.files[0]);

      fileReader.onload = (e) => {
        this.preview = true;
        this.imageLoader = false;
        this.productImage = e.target.result;
      };
    },

    getRegions() {
      this.$http
        .get(`${process.env.VUE_APP_URL}regions`)
        .then((response) => {
          this.productRegion = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getProductCat() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product-categories`)
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    uploadPhoto() {},

    onPickimages() {
      this.imageLoader2 = true;
      this.$refs.fileInput2.click();
    },
    imagesPicked(e) {
      this.preview2 = true;
      this.imageLoader2 = false;
      this.selectedFiles = e.target.files;
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.images.push(this.selectedFiles[i]);
        this.imageLoader = false;
      }

      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs.image[i].src = reader.result;
        };

        reader.readAsDataURL(this.images[i]);
      }
    },
    saveImages() {
      this.imageLoader2 = true;

      const formdata = new FormData();
      formdata.append("productId", this.productId);

      for (var i = 0; i < this.selectedFiles.length; i++) {
        formdata.append("images[]", this.selectedFiles[i]);
      }

      this.$http
        .post(`${process.env.VUE_APP_URL}product/images`, formdata)
        .then((response) => {
          console.log(response);
          this.imageLoader2 = false;
          this.selectedFiles = [];
          this.productId = "";
          this.dialogAddImages = false;
        })
        .catch((error) => {
          this.imageLoader2 = false;
          console.log(error.response);
        });
    },

    editItem(item) {
      this.editedIndex = this.allProducts.indexOf(item);
      this.productData = Object.assign({}, item);
      this.productData.regions = [];
      this.updateProduct = true;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.allProducts.indexOf(item);
      this.productData = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.allProducts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.updateProduct = false;
      this.$nextTick(() => {
        this.productData = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.productData = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    saveProduct() {
      this.loader = true;
      this.errorMessage = [];

      const formdata = new FormData();
      for (var i = 0; i < this.productData.regions.length; i++) {
        formdata.append("regions[]", this.productData.regions[i]);
      }

      formdata.append("productCategoryId", this.productData.productCategoryId);
      formdata.append("userId", this.userId);
      formdata.append("name", this.productData.name);
      formdata.append("price", this.productData.price);
      formdata.append("discount", this.productData.discount);
      formdata.append("description", this.productData.description);
      formdata.append("isActive", this.productData.isActive);
      formdata.append("displayDiscount", this.productData.displayDiscount);
      formdata.append("displayReviews", this.productData.displayReviews);
      formdata.append("quantity", this.productData.quantity);
      formdata.append("needsPrescription", this.productData.isPrescription);
      formdata.append("image", this.selectedFile);

      this.$http
        .post(`${process.env.VUE_APP_URL}product`, formdata)
        .then(() => {
          this.loader = false;
          this.dialog = false;
          this.updateProduct = false;
          this.dialogEditSuccess = true;
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },

    editProduct(productData) {
      this.loader = true;
      this.errorMessage = [];

      const formdata = new FormData();

      if (this.productData.newProductRegions.length > 0) {
        for (var i = 0; i < this.productData.newProductRegions.length; i++) {
          formdata.append("regions[]", this.productData.newProductRegions[i]);
        }
      } else {
        for (var x = 0; x < this.productData.ProductRegions.length; x++) {
          formdata.append(
            "regions[]",
            this.productData.ProductRegions[x].Region.regionId
          );
        }
      }

      formdata.append("productCategoryId", this.productData.productCategoryId);
      formdata.append("name", this.productData.name);
      formdata.append("price", this.productData.price);
      formdata.append("discount", this.productData.discount);
      formdata.append("description", this.productData.description);
      formdata.append("isActive", this.productData.isActive);
      formdata.append("displayDiscount", this.productData.displayDiscount);
      formdata.append("displayReviews", this.productData.displayReviews);
      formdata.append("quantity", this.productData.quantity);
      formdata.append("needsPrescription", this.productData.isPrescription);
      formdata.append("oldImageUrl", productData.productImagePath);

      this.$http
        .put(
          `${process.env.VUE_APP_URL}product/` + productData.productId,
          formdata
        )
        .then((response) => {
          if (response.data.success == true) {
            this.loader = false;
            this.dialog = false;
            this.dialogEditSuccess = true;
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },

    addImages(item) {
      this.dialogAddImages = true;
      this.productId = item.productId;
    },

    refreshPage() {
      this.productData = {};
      this.dialogEditSuccess = false;
      location.reload();
    },
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
}

.orderStatBox {
  padding: 30px !important;
  width: 120px;
  height: 80px;
  border-radius: 10px;
  text-align: center;
  margin: 10px;
}

.orderStatText {
  color: #c2c2c2;
  font-size: 16px;
  text-align: center;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.buttonStyleWarning {
  background: linear-gradient(192.88deg, #111212 43.53%, #fa2d2d 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 35px !important;
  padding-right: 35px !important;
}
</style>
